<template>
  <v-container fluid>
    <v-row>
      <v-form ref="form" @submit.prevent="validateBeforeSubmit">
        <v-col cols="12">
          <v-radio-group
            v-model="vm.havePoliceBeenInformed"
            label="Have the police been informed of the incident/allegation?"
            v-validate="'required'"
            data-vv-as="Have the police been informed of the incident/allegation"
            name="havePoliceBeenInformed"
            :error-messages="errors.collect('havePoliceBeenInformed')"
          >
            <v-radio label="Yes" :value="true"></v-radio>
            <v-radio label="No" :value="false"></v-radio>
          </v-radio-group>
        </v-col>
        <template v-if="vm.havePoliceBeenInformed == true">
          <v-col cols="12">
            <v-text-field
              v-model="vm.officersName"
              label="Officers Name"
              v-validate="'required'"
              data-vv-as="Officers Name"
              name="officersName"
              :error-messages="errors.collect('officersName')"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="vm.policeStation"
              label="Police Station"
              v-validate="'required'"
              data-vv-as="policeStation"
              name="policeStation"
              :error-messages="errors.collect('policeStation')"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="vm.policeEventNumber"
              label="Police event number"
              v-validate="'required'"
              data-vv-as="policeEventNumber"
              name="policeEventNumber"
              :error-messages="errors.collect('policeEventNumber')"
            ></v-text-field>
          </v-col>
        </template>

        <template v-if="vm.havePoliceBeenInformed == false">
          <v-col cols="12">
            <v-text-field
              v-model="vm.policeNotInformedReason"
              label="If the police have not been informed, why not?"
              v-validate="'required'"
              data-vv-as="policeNotInformedReason"
              name="policeNotInformedReason"
              :error-messages="errors.collect('policeNotInformedReason')"
            ></v-text-field>
          </v-col>
        </template>

<template v-if='this.currentIncident.incidentOverview.incidentNotificationType == "5 Day Notification"'>
    <v-col cols="12">
          <v-radio-group
            v-model="vm.isThereADetailedPoliceReport"
            label="Is there a detailed police report?"
            v-validate="'required'"
            data-vv-as="Is there a detailed police report"
            name="isThereADetailedPoliceReport"
            :error-messages="errors.collect('isThereADetailedPoliceReport')"
          >
            <v-radio label="Yes" :value="true"></v-radio>
            <v-radio label="No" :value="false"></v-radio>
          </v-radio-group>
        </v-col>

        <v-col cols="6" v-if='vm.isThereADetailedPoliceReport == true'>
       <v-menu
          v-model="policeReportDate"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="vm.policeReportDate"
              label="Date of the Police report"
            
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="vm.policeReportDate" @input="policeReportDate = false"></v-date-picker>
        </v-menu>
        </v-col>
        </template>

     <v-col cols="12" v-if='vm.isThereADetailedPoliceReport == false'>
            <v-text-field
              v-model="vm.noDetailedPoliceReportReason"
              label="Why is there no detailed Police report?"
              v-validate="'required'"
              data-vv-as="Why is there no detailed Police report"
              name="noDetailedPoliceReportReason"
              :error-messages="errors.collect('noDetailedPoliceReportReason')"
            ></v-text-field>
          </v-col>

        <v-col cols="12">
          <v-radio-group
            v-model="vm.arePoliceInvestigatingTheIncident"
            label="Are police investigating the incident?"
            v-validate="'required'"
            data-vv-as="Are police investigating the incident"
            name="arePoliceInvestigatingTheIncident"
            :error-messages="errors.collect('arePoliceInvestigatingTheIncident')"
          >
            <v-radio label="Yes" :value="true"></v-radio>
            <v-radio label="No" :value="false"></v-radio>
            <v-radio label="Unsure" value="unsure"></v-radio>
          </v-radio-group>
        </v-col>

        <v-col cols="12">
          <v-radio-group
            v-model="vm.haveImpactedPersonsFamilyBeenInformed"
            label="Are the impacted person's family or guardian aware of the incident/allegation?"
            v-validate="'required'"
            data-vv-as="Have the police been informed of the incident/allegation"
            name="haveImpactedPersonsFamilyBeenInformed"
            :error-messages="errors.collect('haveImpactedPersonsFamilyBeenInformed')"
          >
            <v-radio label="Yes" :value="true"></v-radio>
            <v-radio label="No" :value="false"></v-radio>
            <v-radio label="Unsure" value="unsure"></v-radio>
          </v-radio-group>
        </v-col>
        <template v-if="vm.haveImpactedPersonsFamilyBeenInformed == false">
          <v-col cols="12">
            <v-text-field
              v-model="vm.familyNotInformedReason"
              label="If the family or gaurdian have not been informed, why not?"
              v-validate="'required'"
              data-vv-as="familyNotInformedReason"
              name="familyNotInformedReason"
              :error-messages="errors.collect('familyNotInformedReason')"
            ></v-text-field>
          </v-col>
        </template>

        <v-col cols="12">
          <v-radio-group
            v-model="vm.childProtectionAgencyContacted"
            label="If the impacted person is under 18, has the relevant chile protection agency been contacted??"
            v-validate="'required'"
            data-vv-as="Have the police been informed of the incident/allegation"
            name="haveImpactedPersonsFamilyBeenInformed"
            :error-messages="errors.collect('haveImpactedPersonsFamilyBeenInformed')"
          >
            <v-radio label="Yes" :value="true"></v-radio>
            <v-radio label="No" :value="false"></v-radio>
            <v-radio label="Unknown" value="unknown"></v-radio>
            <v-radio label="Not Applicable" value="Not Applicable"></v-radio>
          </v-radio-group>
        </v-col>
        <template v-if="vm.childProtectionAgencyContacted == false">
          <v-col cols="12">
            <v-text-field
              v-model="vm.childProtectionAgencyNotInformedReason"
              label="If not, why hasn't the child protection agency been contacted?"
              v-validate="'required'"
              data-vv-as="childProtectionAgencyNotInformedReason"
              name="childProtectionAgencyNotInformedReason"
              :error-messages="errors.collect('childProtectionAgencyNotInformedReason')"
            ></v-text-field>
          </v-col>
        </template>




 <v-col cols="12" v-if='this.currentIncident.incidentOverview.incidentNotificationType == "5 Day Notification"'>
         
          <v-textarea
            v-model="vm.detailsOfOtherImmediateActionTaken"
            label="Provide details of any other immediate action taken"
            v-validate="'required'"
            data-vv-as="Details of any other immediate action taken"
            name="detailsOfOtherImmediateActionTaken"
            :error-messages="errors.collect('detailsOfOtherImmediateActionTaken')"
          ></v-textarea>
        </v-col>

<template v-if='this.currentIncident.incidentOverview.incidentNotificationType == "Immediate Notification"'>
        <v-col cols="12">
          <h4>Impacted Person</h4>If the incident category is death of a person with a disability, this section does not need to be completed.
          <v-textarea
            v-model="vm.impactedPersonImmediateSupport"
            label="Describe any immediate support that has been offered/provided to the person with disability impacted by the incident (for example medical treatment, counselling, access to advocacy, removed source of harm)"
            v-validate="'required'"
            data-vv-as="support description"
            name="impactedPersonImmediateSupport"
            :error-messages="errors.collect('impactedPersonImmediateSupport')"
          ></v-textarea>
        </v-col>

        <v-col cols="12">
          <h4>Subject of allegation - worker</h4>this only needs ot be completed if there is a worker who is a subject of allegation
          <v-textarea
            v-model="vm.allegationOfWorkerActionTaken"
            label="Describe any immediate action that as been taken in respect to the worker who is the subject of the allegation (for example increased supervision, restriction on current duties, transferred to other duties, suspended with or without pay)"
            v-validate="'required'"
            data-vv-as="Action Taken"
            name="allegationOfWorkerActionTaken"
            :error-messages="errors.collect('allegationOfWorkerActionTaken')"
          ></v-textarea>
        </v-col>

        <v-col cols="12">
          <h4>Subject of allegation - person with disability</h4>this only needs ot be completed if there is a person with disability who is a subject of allegation
          <v-textarea
            v-model="vm.allegationOfPersonWithDisabilityActionTaken"
            label="Describe any immediate action that has been taken or commenced in respect to the person with disability who is the subject of the allegation (for example review of staffing, review of behaviour support needs, medical review, assistance to access support person or advocate)"
            v-validate="'required'"
            data-vv-as="Action Taken"
            name="allegationOfPersonWithDisabilityActionTaken"
            :error-messages="errors.collect('allegationOfPersonWithDisabilityActionTaken')"
          ></v-textarea>
        </v-col>
</template>


<template v-if='this.currentIncident.incidentOverview.incidentNotificationType == "5 Day Notification"'>
      <template v-if='this.currentIncident.incidentOverview.hasImpactedPersonDeathOccured == false'>
        <v-col cols="12">
          <h4>Impacted Person</h4>
          <v-textarea
            v-model="vm.impactedPerson5DaySupportOffered"
            label="Describe any support that has been offered/provided to the person with disability impacted by the incident (for example medical treatment, counselling, access to advocacy, removed source of harm)"
            v-validate="'required'"
            data-vv-as="support description"
            name="impactedPerson5DaySupportOffered"
            :error-messages="errors.collect('impactedPerson5DaySupportOffered')"
          ></v-textarea>
            <v-textarea
            v-model="vm.furtherActionBeingConsidered"
            label="Describe any further action bring considered for the person with disability impacted by the incident"
            v-validate="'required'"
            data-vv-as="further action"
            name="furtherActionBeingConsidered"
            :error-messages="errors.collect('furtherActionBeingConsidered')"
          ></v-textarea>
        </v-col>
      </template>

       <template v-if='this.currentIncident.subjectsOfAllegationWorker.allegationSubjects == true'>
        <v-col cols="12">
          <h4>Subject of allegation - worker</h4>
          <v-textarea
            v-model="vm.allegationOfWorker5DayActionTaken"
            label="Describe any action that as been taken in respect to the worker who is the subject of the allegation (for example increased supervision, restriction on current duties, transferred to other duties, suspended with or without pay)"
            v-validate="'required'"
            data-vv-as="Action Taken"
            name="allegationOfWorker5DayActionTaken"
            :error-messages="errors.collect('allegationOfWorker5DayActionTaken')"
          ></v-textarea>
          <v-textarea
            v-model="vm.furtherActionBeingConsideredWorker"
            label="Describe any further action bring considered in respect to the worker who is the subject of the allegation"
            v-validate="'required'"
            data-vv-as="further action"
            name="furtherActionBeingConsideredWorker"
            :error-messages="errors.collect('furtherActionBeingConsideredWorker')"
          ></v-textarea>
        </v-col>

       </template>
       <template v-if='this.currentIncident.subjectsOfAllegationDisability.allegationSubjects == true'>
        <v-col cols="12">
          <h4>Subject of allegation - person with disability</h4>
          <v-textarea
            v-model="vm.allegationOfPersonWithDisabilityActionTaken"
            label="Describe any immediate action that has been taken or commenced in respect to the person with disability who is the subject of the allegation (for example review of staffing, review of behaviour support needs, medical review, assistance to access support person or advocate)"
            v-validate="'required'"
            data-vv-as="Action Taken"
            name="allegationOfPersonWithDisabilityActionTaken"
            :error-messages="errors.collect('allegationOfPersonWithDisabilityActionTaken')"
          ></v-textarea>
          <v-textarea
            v-model="vm.furtherActionBeingConsideredPersonWithDisability"
            label="Describe any immediate action that has been taken or commenced in respect to the person with disability who is the subject of the allegation (for example review of staffing, review of behaviour support needs, medical review, assistance to access support person or advocate)"
            v-validate="'required'"
            data-vv-as="Action Taken"
            name="furtherActionBeingConsideredPersonWithDisability"
            :error-messages="errors.collect('furtherActionBeingConsideredPersonWithDisability')"
          ></v-textarea>
        </v-col>
       </template>
</template>



        <v-btn color="primary" type="submit">Continue</v-btn>
      </v-form>
    </v-row>
  </v-container>
</template>


<script>
import Vue from "vue";
import VeeValidate from "vee-validate";

Vue.use(VeeValidate);

export default {
  name: "immediate-action-taken",

  data: () => ({
    vm: [],
policeReportDate : "",
    gender: ["Male", "Female", "Indeterminate", "Intersex", "Unspecified"],
    primaryDisability: [
      "Autism",
      "Intellectual disability",
      "Cerebral Palsy",
      "Psychosocial disability",
      "Other neurological",
      "Other physical",
      "Acquired brain injury",
      "Visual impairment",
      "Other sensory/speech",
      "Multiple sclerosis",
      "Stroke",
      "Spinal cord injury",
      "Other"
    ],
    secondaryDisability: [
      "Autism",
      "Intellectual disability",
      "Cerebral Palsy",
      "Psychosocial disability",
      "Other neurological",
      "Other physical",
      "Acquired brain injury",
      "Visual impairment",
      "Other sensory/speech",
      "Multiple sclerosis",
      "Stroke",
      "Spinal cord injury",
      "Other"
    ],
    behavioursOfConcern: [
      "Food-related",
      "Eating non-food items",
      "Propert damage",
      "Physical aggresion",
      "Verbal aggresion",
      "Harm to self",
      "Unintentional self-risk",
      "Leaving premises without support",
      "Refusal to do things",
      "Repetitive or unusual habits",
      "Offending behaviour",
      "Sexually inappropriate behaviour",
      "Other"
    ],
    communication: [
      "Verbal communication",
      "Adjusted verbal language",
      "Electronic communication",
      "Picture communication",
      "Sign language",
      "Other signing",
      "Use of gestures",
      "Interpreter",
      "Other"
    ]
  }),
  props: {
    msg: String
  },
  mounted() {
    this.init();
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    currentCase() {
      return this.$store.getters.currentCase;
    },
    currentIncident() {
      return this.$store.getters.currentIncident;
    }
  },
  methods: {
    init() {
      this.vm =
        this.currentIncident == undefined ||
        this.currentIncident.immediateActionTaken == undefined
          ? {}
          : this.currentIncident.immediateActionTaken;
    },
    validateBeforeSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.currentIncident.immediateActionTaken = this.vm;
          this.$store.dispatch("setCurrentIncident", this.currentIncident);
          this.next();
          return;
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    },

    next() {
      this.$emit("next-step");
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

